/**
 * Author: Emmanuel Munoz
 * File to save all of the portfolio data that gets used to render the site
 */

/**
 * Projects Data, contains a list of all projects that will get rendered to the page
 * Each project must have following params:
 * @param {String} title - The title of the project
 * @param {String} link - The link/website of the project
 * @param {String} desc - The description of the project
 */
export const PROJECTS = {
   RESOURCE_TRACKER: {
      title: "Resource Tracker",
      link: "https://depts.washington.edu/uwtslwebdev/ResourceTracker/",
      desc: "Resource-Tracker is a web app project built in-collaboration with UWT which allows students to view the currently available resources they can utilize. "
   },
   HUSKY_PREP: {
      title: "HuskyPrep",
      link: "https://huskyprep.me",
      desc: "HuskyPrep allows students to practice previous exam problems for weed out classes with answers and solution steps provided by tutors."
   },
   UW_TUTORING:    {
      title: "UW Tutoring",
      link: "http://students.washington.edu/em66/UWTU/",
      desc: "UW-Tutoring is a web app that asks a UW student what classes they are having trouble with and gives a list of all the on-campus tutoring centers that specialize in that subject. The project came out of my own frustrations as a freshman not knowing what study centers were available and which ones can help with the classes I was taking."
   }
};

/**
 * Experiences Object, contains all of the experiences that can be selected for rendering
 * Each experience is its own object and must have following params:
 * @param {String} title - The title of the experience/position
 * @param {String} org - The organization/company the experience took place
 * @param {String[]} desc - All descriptions of the experience
 */
export const EXPERIENCES = {
   Bioinformatics_Research_Intern: {
      title: "Bioinformatics Research Intern",
      org: "Statistical Physics of Evolving Systems Lab",
      desc: ["Ran Data Analysis on gene sequencing of HIV-1 patients to study long-term B-cell response using Python, Jupyter, and PySpark"]
   },
   Oracle_Intern: {
      title: "Software Engineer Intern",
      org: "Oracle Cloud Infrastructure",
      desc: [
         "Used Terraform, Java, and Golang to automate OCI cloud compute instance health alarms and monitoring",
         "Used Grafana to display compute instance health status and usage data"
      ]
   },
   Namatad_Intern: {
      title: "Software Engineer Intern",
      org: "Namatad Inc.",
      desc: [
         "Developed a marketing site using Gatsby to showcase company information and a Content-Management-Site (CMS) using React for non-developers to easily update marketing information.",
         "Desgined and implemented a REST API to service an in-house CMS to update marketing site.",
         "Built and developed overall project infrastructure utilizing AWS Lambda, Amplify, and S3 focusing on low-cost and auto-scaling."
      ]
   },
   CSE_TA: {
      title: "Teacher's Assistant (CSE154)",
      org: "Paul G. Allen School of Computer Science and Engineering",
      desc: [
         "Lead a bi-weekly class of ~15 students going in-depth into lecture material",
         "Helped create new class material for the transition to teach new back-end language",
         "Assisted with grading assignments and exams"
      ]
   },
   UWT_Dev: {
      title: "Web Developer (Full-Stack)",
      org: "UWT Student Life Department",
      desc: [
         "Created a full-stack web application that allows students to see currently available resources",
         "Created a Content Management System that allows advisors to update the site without writing code. Both built with HTML/CS/JS for the front-end and PHP/SQL for the back-end."
      ]
   },
   BIODEPOT: {
      title: "Software Engineer Intern",
      org: "BioDepot LLC.",
      desc: [
         "Re-worked proprietary bioinformatics full-stack server-side-rendered Flask app from the ground up to use React",
         "Created onboarding materials and example practice projects to prepare new developers for the codebase",
         "Developed desktop GUI for proprietary bioinformatics web app, built with React and Neutralino"
      ]
   },
   UWT_TA: {
      title: "Teacher's Assistant (TCSS380)",
      org: "UWT School of Engineering and Technology",
      desc: [
         "Helped students work through functional programming labs",
         "Helped with test runs of exams to ensure fairness for students",
         "Assisted with grading labs and exams"
      ]
   },
   UWT_TUTOR: {
      title: "Student Mentor",
      org: "UWT School of Engineering and Technology",
      desc: [
         "Helped students work through various programming assignments",
         "Provided registration and programming project advice for students"
      ]
   }
};

/**
 * A list of all the technologies I have used in the past as icons
 * @param {String} - The react-icons name for the icon
 */
export const TECH_ICONS = [
   "fab fa-git fa-3x",
   "fab fa-html5 fa-3x",
   "fab fa-css3-alt fa-3x",
   "fab fa-js-square fa-3x",
   "fab fa-php fa-3x",
   "fab fa-python fa-3x",
   "fab fa-node-js fa-3x",
   "fab fa-aws fa-3x",
   "fab fa-react fa-3x"
];