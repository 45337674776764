import Header from './parts/Header';
import Bio from './parts/Bio';
import Projects from './parts/Projects';
import Experiences from './parts/Experiences';
import Tech from './parts/Tech';
import Contact from './parts/Contact';

function App() {
  return (
    <div className="container">
      <Header />

      <main className="">
        <Bio />
        <Projects />
        <Experiences />
        <Tech />
        <Contact />
      </main>
    </div>
  );
}

export default App;
