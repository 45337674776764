/**
 * Author: Emmanuel Munoz
 * Renders the Projects part of the page
 */
import ProjectItem from '../components/ProjectItem';
import { PROJECTS } from '../Data';

const Projects = () => {
   const selectedProjects = [
      PROJECTS.RESOURCE_TRACKER,
      PROJECTS.UW_TUTORING
   ];
   return (
      <div className="container d-flex flex-column mb-5" id="projects">
         <h2 className="mb-3">Projects I've worked on</h2>

         {
            selectedProjects.map((item) => 
               <ProjectItem link={item.link} title={item.title} key={item.title}>
                  { item.desc }
               </ProjectItem>
            )
         }
      </div>
   );
};

export default Projects;