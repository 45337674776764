/**
 * Author: Emmanuel Munoz
 * Renders the Experiences part of the page
 */
import { EXPERIENCES } from '../Data';
import ExperienceItem from '../components/ExperienceItem';

const Experiences = () => {
   const selectedExperiences = [
      EXPERIENCES.BIODEPOT,
      EXPERIENCES.UWT_Dev,
      EXPERIENCES.Bioinformatics_Research_Intern,
      EXPERIENCES.UWT_TA,
      EXPERIENCES.CSE_TA,
      EXPERIENCES.UWT_TUTOR
   ];
   
   return (
      <div className="container mb-5" id="experiences">
            <h2 className="mb-3">Previous Experiences</h2>
            <div className="d-flex flex-row flex-wrap justify-content-around">
               {
                  selectedExperiences.map((exp) => 
                     <ExperienceItem 
                        key={exp.org + exp.title + exp.desc[0]} 
                        title={exp.title} 
                        org={exp.org} 
                        desc={exp.desc} 
                     />
                  )
               }   
         </div>
      </div>
   );
};

export default Experiences;