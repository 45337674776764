import { AiOutlineMail } from 'react-icons/ai';
import { FaGithub, FaLinkedin } from 'react-icons/fa';

const Contact = () => {
   return (
      <div className="container">
         <h3 className="text-left">Contact Info</h3>
         <ul className="list-inline d-flex justify-content-center">
            <li className="list-inline-item">
               <a href="https://github.com/M00ny0z?tab=repositories">
                  <FaGithub className="contact contact-icon" />
               </a>
            </li>
            <li className="list-inline-item">
               <a href="mailto:1mannymunoz@gmail.com">
                  <AiOutlineMail className="contact contact-icon" />
               </a>
            </li>
            {/* <li className="list-inline-item">
               <a href="https://www.linkedin.com/in/m00ny0z/">
                  <FaLinkedin className="contact contact-icon" />
               </a>
            </li> */}
         </ul>
      </div>
   );
};

export default Contact;