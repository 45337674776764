/**
 * Author: Emmanuel Munoz
 * Renders the Header part of the page
 */
const Header = () => {
   return (
      <header>
         <h1 className="text-left pl-3 text-white">Emmanuel "Manny" Munoz</h1>
         <hr />
         <div className="container">
            <h2 className="text-center text-white">
               "Excellence isn't an art, it's a way of life. <br /> We are what we repeatedly do." 
               - Furi&trade;
            </h2>
         </div>
      </header>
   );
};

export default Header;