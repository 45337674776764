import React from 'react';

const Bio = () => {
   const first_bio = `
   Hello! My name is Emmanuel Munoz but I go by Manny. I was born and raised in 
   Tacoma, WA by two amazing Colombian immigrants. I am a Computer Science and 
   Systems student at the University of Washington with a passion
   for education! I am a member of both the latino and gay communities and proud
   of it! After college, I hope to work as a professor teaching computer science.
   The quote at the top is my personal mantra in life.
   `;
   const second_bio = `
   Greetings! My name is Emmanuel but I go Manny. Born and raised in Tacoma, WA. 
   I am a recent grad, having studied computer science at UW Tacoma and have a passion for software engineering and education. 
   Through college and internships, 
   I have more than a year of professional dev experience working through a variety of fields such as NLP, Data Science, 
   Machine Learning, Bioinformatics, full-stack web development, and more! 
   The quote at the top is my personal mantra in life. 
   `;
   return (
      <div className="container mb-5" id="bio">
         
            <h3 className="mb-3 text-white">Bio</h3>
            <div className="col-sm px-5 d-flex flex-column align-items-center">
               <p className="regtext text-white">
                  { second_bio }
               </p>
            </div>

      </div>   
   );
};

export default Bio;