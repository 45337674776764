/**
 * Author: Emmanuel Munoz
 * Renders a single experience item
 * PROPS:
 *    @prop {String} title - The title of the experience
 *    @prop {String} org - The organization/company the experience took place in
 *    @prop {String[]} desc - The description of the experience
 */

const ExperienceItem = (props) => {


   return (
      <div className="card mb-4 bg-exp" style={{width: '18rem'}}>
         <div className="card-body">
            <h5 className="card-title">{props.title}</h5>
            <h6 className="card-subtitle mb-3 border-bottom border-white border-5">
               <i>{ props.org }</i>
            </h6>
            {
               props.desc.map((item) => 
                  <p className="card-text" key={item}>
                     { item }
                  </p>
               )
            }
         </div>
      </div>
   );
};

export default ExperienceItem;