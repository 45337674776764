import { FaGit, FaPhp, FaPython, FaNode, FaAws, FaReact } from 'react-icons/fa';
import { SiJavascript, SiPytorch, SiJupyter, SiMysql } from 'react-icons/si';

const Tech = () => {
   const style = "bs-icon tech";
   const ICONS = [
      <FaGit className={style} />,
      <SiJavascript className={style} />,
      <FaReact className={style} />,
      <FaPhp className={style} />,
      <FaNode className={style} />,
      <SiMysql className={style} />,
      <FaAws className={style} />,
      <FaPython className={style} />,
      <SiPytorch className={style} />,
      <SiJupyter className={style} />
   ];

   return (
      <div className="container mb-5">
         <h2 className="mb-3">Technologies</h2>

         <ul id="contact" className="list-inline d-flex justify-content-center container flex-wrap">
            {
               ICONS.map((item, idx) =>
                  <li className="list-inline-item tech" key={idx}>
                     { item }
                  </li>
               )
            }
         </ul>
      </div>
   );
};

export default Tech;