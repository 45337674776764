/**
 * Author: Emmanuel Munoz
 * Renders a single project item
 * PROPS:
 *    @prop {String} title - The title of the project
 *    @prop {String} link - The link to the project
 *    @prop {String} children - The description of the project
 */

const ProjectItem = (props) => {
   return (
      <a 
         className="jumbotron jumbotron-fluid bg-prj rounded" 
         href={props.link}
         target="_blank"
         rel="noopener noreferrer"
      >
         <div className="container">
            <h1 className="display-4 mb-4 text-wrap container">{props.title}</h1>
            <p className="lead">
               {props.children}
            </p>
         </div>
      </a>
   );
};

export default ProjectItem;